import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// import HeaderAccueil from './components/HeaderAccueil';
import FeatherIcon from 'feather-icons-react'
import api from './../http/global-vars'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import MessageAllPage from '../backend/components/MessageAllPage'
import isAuthenticate from './../modules/Secure/Secure'
import ResetPasswordModal from './ResetPasswordModal'

export default class Connexion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      api: api,
      fields: {},
      errors: {},
      isLoginInProgress: false,
      isSuccessLogin: false,
      isErrorLogin: false,
      is_empty_email: false,
      is_empty_password: false,
      isInvalidCredential: false,
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      userID: window.localStorage.getItem('userID'),
      userType: window.localStorage.getItem('userType'),
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmitLogin = this.onSubmitLogin.bind(this)
  }

  componentDidMount() {
    // this.onTestUserConnectedStatus()
  }

  onTestUserConnectedStatus = () => {
    var isNotConnected = isAuthenticate()
    var userType = this.state.userType
    var isFirstConnexion = window.localStorage.getItem('userFirstConnexion')

    if (!isNotConnected && userType === 'student') {
      if (isFirstConnexion === true) {
        window.location = '/welcome'
      } else {
        window.location = '/tous-les-modules'
      }
    } else if (!isNotConnected && userType === 'admin') {
      window.location = '/tableau-de-bord'
    }
  }

  handleChange(e) {
    let fields = this.state.fields
    fields[e.target.name] = e.target.value

    if (e.target.name === 'email') {
      fields[e.target.name] = e.target.value
    }

    this.setState({
      fields: fields,
    })
    console.log(fields)
  }

  onValidateLoginForm() {
    let fields = this.state.fields
    let errors = this.state.errors
    var isValidForm = true

    if (!fields['email']) {
      isValidForm = false
      this.setState({
        is_empty_email: true,
      })
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        })
      }, 5000)

      errors['email'] = 'Ce champ est obligatoire'
    }
    if (!fields['password']) {
      isValidForm = false
      this.setState({
        is_empty_password: true,
      })
      setTimeout(() => {
        this.setState({
          is_empty_password: false,
        })
      }, 5000)

      errors['password'] = 'Ce champ est obligatoire'
    }

    this.setState({
      errors: errors,
    })

    return isValidForm
  }

  onSubmitLogin(e) {
    e.preventDefault()

    if (this.onValidateLoginForm()) {
      this.setState({
        isLoginInProgress: true,
      })

      var api = this.state.api
      var data = this.state.fields
      var fd = new FormData()
      fd.append('email', data.email.toLowerCase())
      fd.append('password', data.password)

      // return console.log(data.email.toLowerCase())

      axios
        .post(api + 'auth/get-token', fd)
        .then((response) => {
          //console.log(response)
          if (response.status === 200) {
            if (response.data.status === 'failure') {
              toast.error(response.data.message)

              this.setState({
                isInvalidCredential: true,
                isLoginInProgress: true,
              })

              setTimeout(() => {
                this.setState({
                  isInvalidCredential: false,
                  isLoginInProgress: false,
                })
              }, 5000)
            } else {
              var token = response.data.token
              window.localStorage.setItem('userToken', token)
              let requestConfig = {
                headers: { Authorization: `Bearer ${token}` },
              }
              this.onGetConnectedUserInfos(requestConfig)
            }
          } else if (
            response.data.success === false &&
            response.data.invalid_credential === true
          ) {
            toast.error('Vos identifiants de connexion ne sont pas valides !')
            this.setState({
              isInvalidCredential: true,
            })

            setTimeout(() => {
              this.setState({
                isInvalidCredential: false,
              })
            }, 5000)
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(
            'Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.',
          )
          this.setState({
            isErrorLogin: true,
            isLoginInProgress: false,
          })

          setTimeout(() => {
            this.setState({
              isErrorLogin: false,
            })
          }, 5000)
        })
    }
  }

  onGetConnectedUserInfos = (config) => {
    var api = this.state.api
    axios
      .get(api + 'me/', config)
      .then((response) => {
        //console.log(response)
        this.setState({
          isLoginInProgress: false,
        })
        if (response.status === 200) {
          window.localStorage.setItem('userID', response.data.data.id)
          window.localStorage.setItem(
            'userFirstName',
            response.data.data.first_name,
          )
          window.localStorage.setItem(
            'userLastName',
            response.data.data.last_name,
          )
          window.localStorage.setItem(
            'userFullname',
            response.data.data.first_name + ' ' + response.data.last_name,
          )
          window.localStorage.setItem('userType', response.data.data.user_type)
          window.localStorage.setItem(
            'userFirstConnexion',
            response.data.data.first_connexion,
          )
          var userType = response.data.data.user_type
          var isFirstConnexion = response.data.data.first_connexion
          //console.log(response.data.data)

          toast.success('Connexion réussie !')
          this.setState({
            isSuccessLogin: true,
          })
          setTimeout(() => {
            this.setState({
              isSuccessLogin: false,
            })
          }, 5000)
          console.log(userType)
          if (userType === 'student') {
            if (isFirstConnexion === true) {
              setTimeout(() => {
                window.location = '/welcome'
              }, 3000)
            } else {
              setTimeout(() => {
                window.location = '/tous-les-modules'
              }, 3000)
            }
          } else {
            setTimeout(() => {
              window.location = '/tableau-de-bord'
            }, 3000)
          }
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(
          'Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.',
        )
        this.setState({
          isErrorLogin: true,
          isLoginInProgress: false,
        })

        setTimeout(() => {
          this.setState({
            isErrorLogin: false,
          })
        }, 5000)
      })
  }

  render() {
    return (
      <div className="p-t-100">
        <div className="dis-flex">
          <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 ml-auto mr-auto">
            <div className="card-connexion py-5">
              <p className="titre-connexion">Connectez-vous</p>
              <div className="row contact-support-row">
                <div className="col-md-12 contact-support-col">
                  <div className="alert alert-info gfa-alert-info">
                    Si vous rencontrez des problémes lors de votre connexion,
                    veuillez contacter le support technique en cliquant{' '}
                    <a
                      href="mailto:defaru.support@volkeno.sn"
                      className="contact-support-link"
                    >
                      ICI
                    </a>
                  </div>
                </div>
              </div>
              <form id="login-form">
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="mail" />
                    </span>
                  </div>
                  <input
                    name="email"
                    id="email"
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Adresse mail"
                  />
                  {this.state.is_empty_email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {' '}
                      {this.state.errors.email}{' '}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    name="password"
                    id="password"
                    onChange={(e) => this.handleChange(e)}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Mot de passe"
                  />
                  {this.state.is_empty_password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {' '}
                      {this.state.errors.password}{' '}
                    </div>
                  )}
                </div>

                <div className="dis-flex justify-content-end m-b-40">
                  <button
                    onClick={(e) => e.preventDefault()}
                    type="button"
                    className="mdpOublie"
                    data-toggle="modal"
                    data-target=".open-reset-password-modal"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>

                <ResetPasswordModal />

                {this.state.isLoginInProgress === false ? (
                  <button
                    type="submit"
                    onClick={(e) => this.onSubmitLogin(e)}
                    className="btn-connexion"
                  >
                    Connexion
                  </button>
                ) : (
                  <button className="btn-connexion in-progress-btn">
                    Connexion en cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </form>
              <p className="mt-5 footer-text-form">
                Pas encore de compte ?{' '}
                <NavLink className="footer-link-form" to="/inscription">
                  S’inscrire
                </NavLink>
              </p>
            </div>
          </div>
        </div>

        {this.state.isSuccessLogin ||
        this.state.isErrorLogin ||
        this.state.isInvalidCredential ? (
          <ToastContainer />
        ) : (
          ''
        )}

        <div className="">
          <MessageAllPage fullWidth={true} />
        </div>
      </div>
    )
  }
}
