import React from "react";
import { NavLink } from "react-router-dom";
import MessageAllPage from "../backend/components/MessageAllPage";
import ContactSupport from "./components/ContactSupport";
import HeaderAccueil from "./components/HeaderAccueil";

function WelcomePage() {
  return (
    <div>
      <HeaderAccueil />
      <div className="bg-white p-t-50 p-b-40">
        <div className="container">
          <div className="row m-b-20">
            <div className="col-md-12 welcome-page-introduction">
              <div className="text-dark fw-700 f-f-poppins lh-1-5 welcome-textual-bloc">
                <div className="intro-firsty">
                  Bonjour et bienvenu(e) sur DEFARU. <br /> Ta
                  plateforme sénégalaise pour les formations en ligne sur les
                  Soft Skills et l’Entrepreneuriat !{" "}
                </div>
                <div className="intro-secondly">
                  Nous sommes ravis de te voir parmi nous !
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-5">
              <div className="home-illustration-container row">
                <div className="col-md-6 offset-md-3 dis-flex justify-content-center itm-center home-illustration-container-col">
                  <img
                    className="w-full home-illustration welcome-page-img"
                    src="img/img-accueil.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="learning-programme-content-container mb-5">
            <div className="row learning-programme-content-row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <p className="content-text-welcome m-b-20 text-center">
                  Voici des informations sur le{" "}
                  <span className="w-600">contenu</span> des modules à découvrir
                  sur la plateforme :
                </p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 learning-programme-content-col">
                <div className="learning-programme-content-description">
                  <div className="learning-theme-programme-item-content">
                    <p className="content-text-welcome m-b-20">
                      {" "}
                      Dans le module{" "}
                      <span className="fw-600">Communication</span>, tu
                      découvriras les différents types de communication et tu
                      apprendras pourquoi des bonnes capacités en matière de
                      communication sont importantes pour ton avenir
                      professionnel.
                    </p>
                    <p className="content-text-welcome m-b-20">
                      Dans le module{" "}
                      <span className="fw-600">L’éthique de travail</span>, tu
                      apprendras la signification du terme « éthique de travail
                      », son application dans la vie professionnelle et les
                      règles qui en découlent.
                    </p>
                    <p className="content-text-welcome m-b-20">
                      Dans le module{" "}
                      <span className="fw-600">La gestion de carrière</span>, tu
                      seras amené(e) à te focaliser sur les aspects pratiques
                      liés à la gestion de ta carrière. D’abord tu identifieras
                      tes besoins, tes intérêts, et tes points forts pour
                      ensuite approfondir les aspects relatifs à tes démarches
                      de recherche d’emploi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 learning-programme-content-col">
                <div className="learning-theme-programme-item-content">
                  <p className="content-text-welcome m-b-20">
                    Le module{" "}
                    <span className="fw-600">Mon modèle d’affaires*</span>{" "}
                    traite du sujet du modèle d’affaires d’une entreprise. Dans
                    ce module tu découvriras le concept de la vision
                    d’entreprise et les différents types d’entreprises et tu
                    apprendras comment l’approche simplifiée de démarrage peut
                    t’aider à lancer tes activités entrepreneuriales.
                  </p>
                  <p className="content-text-welcome m-b-20">
                    Le module{" "}
                    <span className="fw-600">Gestion des finances*</span> aborde
                    la question de la viabilité des entreprises et de la gestion
                    financière de ton entreprise et donne un aperçu simplifié
                    des indicateurs économiques les plus pertinents.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="autres-infos-section-container">
            <div className="demarches-section-container mb-5">
              <h3 className="f-f-poppins fw-700 mb-3 item-title">Démarche</h3>
              <p className="content-text-welcome">
                A l’issue de chaque module de formation complété, tu recevras un{" "}
                <span className="fw-600">certificat</span>. Mais pour obtenir le
                certificat, il faudra d’abord parcourir l’intégralité du module
                et réaliser tous les exercices qui te sont proposés. Il n’y a
                pas de limite de temps, cependant nous t’encourageons à terminer
                chaque module dans un délai de 3 semaines pour un apprentissage
                optimal.
              </p>
              <p className="content-text-welcome">
                Les modules de formations peuvent bien entendu être réalisés
                individuellement.
              </p>
              <p className="content-text-welcome">
                Le certificat de participation sera disponible au format PDF
                téléchargeable directement sur la plateforme DEFARU{" "}
                <span className="text-bg">
                  et via un lien qui te sera envoyé par email
                </span>
                .
              </p>
            </div>
            <div className="commencer-section-container">
              <h3 className="f-f-poppins fw-700 m-b-35 item-title">
                Commençons
              </h3>
              <p className="content-text-welcome">
                Nous te souhaitons une bonne formation et nous espérons que tu
                profiteras pleinement de cette expérience en ligne.
              </p>
              <div className="content-text-welcome">
                Si tu as des difficultés de connexion ou des difficultés à
                visionner le contenu, surtout n’hésite pas à
                <button
                  className="link-contact-support-welcome"
                  data-toggle="modal"
                  data-target=".contactSupportModalWelcome"
                >
                  contacter le support
                </button>{" "}
                ou à envoyer un mail à l'adresse suivante{" "}
                <a
                  className="no-link link-contact-support-welcome"
                  target="_blank"
                  href="mailto:defaru.support@volkeno.sn"
                  rel="noreferrer"
                >
                  defaru.support@volkeno.sn{" "}
                </a>
                .
                <div
                  className="modal fade contactSupportModalWelcome"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-card">
                      <ContactSupport />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row dis-flex justify-content-center m-t-60">
            <div className="col-md-4 start-btn-container">
              <NavLink
                className="btn-theme fw-600 f-f-poppins dis-flex justify-content-center"
                to="/fiche-identification"
              >
                Commencer
              </NavLink>
            </div>
          </div>
          <div className="new-modules-law-required">
            <quote>
              <span className="law-asterix">*</span> Les modules Mon modèle
              d’affaires et Gestion des finances ont été développés dans le
              cadre de Synergie Genre et Entrepreneuriat (SyGEn), une formation
              en ligne en auto-apprentissage destinée aux entrepreneurs, qui
              leur permet de mieux comprendre le monde des affaires et d'y
              intégrer la dimension de genre afin de prospérer avec un nouvel
              état d'esprit. Le cours en 5 modules a été développé par le projet
              L'enseignement et la formation techniques et professionnelles
              agricoles pour les femmes (ATVET4W), mis en œuvre par l'agence de
              développement de l'Union africaine (AUDA-NEPAD) avec le soutien
              technique et financier de Deutsche Gesellschaft für Internationale
              Zusammenarbeit (GIZ) GmbH et de GFA Consulting.
            </quote>
          </div>
        </div>
      </div>

      <div className="">
        <MessageAllPage fullWidth={true} />
      </div>
    </div>
  );
}

export default WelcomePage;
