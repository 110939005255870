import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import ContactSupport from '../components/ContactSupport';

export default class NavbarDashboardMobile extends Component {
    constructor(props){
        super(props)
        this.state = {
            
        }
    }
  
    render(){
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-white py-4">
                <button className="navbar-toggler m-l-15" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                <NavLink className="dis-flex" to="/accueil">
                    <img className="logo-dashboard-mobile" src={`/img/defaru300_100px.png`} alt="Logo GFA" />
                </NavLink>
        
                <div className="collapse navbar-collapse bg-theme" id="navbarNavDropdown">
                    <ul className="navbar-nav m-t-30">
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom active" to="/tous-les-modules" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="grid" />
                                Tous les Modules
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom " to="/mon-parcours" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="layout" />
                                Mon parcours
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom " to="/forum" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="users" />
                                Forum
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom " to="/mon-certificat" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="award" />
                                Mes certificats
                            </NavLink>
                        </li>
                    </ul>
        
                    <div className="container flex-col m-b-30 m-t-50">
                        <p className="text-white f-f-inter fw-600 fs-14 m-b-20">Besoin d’aide ?</p>
                        <button type="button" className="btn-icon-transparent" data-toggle="modal" data-target=".contactSupportModalMobile"><FeatherIcon className="icon-contact-support" icon="mail" /> Contacter le support</button>
                        <div className="modal fade contactSupportModalMobile" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content modal-card">
                                    <ContactSupport />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
            </nav>
        );
    }
}