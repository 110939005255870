import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import ItemIntroModule from "./components/ItemIntroModule";
import ItemSessionModule from "./components/ItemSessionModule";
import MessageAllPage from "./components/MessageAllPage";
import ModalAddSession from "./components/ModalAddSession";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import NavbarVerticalAdmin from "./components/NavbarVertical";

export default class ModuleDetailsV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module_id: this.props.match.params.id,
      module: this.props.location?.state,
    };
  }

  componentDidMount() {
    console.log("id module=>", this.props);
  }

  render() {
    console.log(this.props);
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobileAdmin />
          </div>
        </div>

        <HeaderDashboardAdmin />

        <div className="row">
          {/* Navbar Desktop  */}
          {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
          <div className="nav-container">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ top: "40px" }}
            >
              <NavbarVerticalAdmin />
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-12"></div>

          <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
            <div className="container-fluid">
              <p className="m-b-30">
                <NavLink className="routing-view-url" to="/module">
                  Module
                </NavLink>
                <FeatherIcon
                  className="icon-routing-view-url"
                  icon="chevron-right"
                />
                <NavLink className="routing-view-url" to="#">
                  {this.state.module?.name}
                </NavLink>
              </p>

              <ItemIntroModule
                module_id={this.state.module_id}
                module={this.state.module}
              />
              <ItemSessionModule
                module_id={this.state.module_id}
                module={this.state.module}
              />
              <button
                className="btn-light-extra-large"
                data-toggle="modal"
                data-target=".modalAddSession"
              >
                <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
                Ajouter une session
              </button>
              
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}
