/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import MessageAllPage from "../../backend/components/MessageAllPage";
import HeaderAccueilV2 from "./HeaderAccueilV2";
import { Redirect } from "react-router-dom";

function WelcomeV4() {
  let token = window.localStorage.getItem("userToken");
  const userType = window.localStorage.getItem("userType");

  if (token) {
    if (userType === "student") {
      return <Redirect to="/tous-les-modules" />;
    } else if (userType === "admin") {
      return <Redirect to="/tableau-de-bord" />;
    }
  }

  return (
    <div>
      {/* <HeaderAccueil /> */}
      <HeaderAccueilV2 />
      <div className="bg-white  p-t-200 p-b-40">
        <div className="container">
          <div className="m-b-20">
            <div className="learning-theme-col">
              <div className="learning-theme-description">
                <p className="content-text-welcome mb-0">
                  Tu souhaites améliorer ton{" "}
                  <span className="text-color fw-700">employabilité</span> ?{" "}
                  <br />
                </p>
                <p className="content-text-welcome mb-0">
                  Tu souhaites donner un nouveau souffle à{" "}
                  <span className="text-color fw-700">
                    ton projet professionnel
                  </span>{" "}
                  et à <span className="text-color fw-700">ta carrière</span> ?{" "}
                  <br />
                </p>
                <p className="content-text-welcome">
                  Tu souhaites te{" "}
                  <span className="text-color fw-700">former gratuitement</span>{" "}
                  et à{" "}
                  <span className="text-color fw-700">ton propre rythme </span>?
                </p>
              </div>
            </div>
          </div>

          <div className="home-illustration-container row">
            <div className="col-md-6 offset-md-3 dis-flex justify-content-center itm-center home-illustration-container-col">
              <img
                className="w-full home-illustration"
                src="img/img-accueil.png"
                alt="Couverture"
              />
            </div>
          </div>
          <div className="learning-theme-col">
            <div className="learning-theme-description">
              <p className="content-text-welcome">
                La plateforme{" "}
                <a className="h-theme fw-700 text-underline" href="#">
                  www.defaru.sn
                </a>{" "}
                te donne la possibilité de bénéficier de{" "}
                <span className="text-color fw-700">
                  modules de formation gratuits en ligne
                </span>{" "}
                sur des compétences Soft Skills et des compétences
                entrepreneuriales !
              </p>
              <p className="content-text-welcome">
                Les <span className="text-color fw-700">Soft Skills</span> sont
                essentiels pour l’orientation professionnelle, la recherche
                d’emploi et l’intégration durable dans le milieu professionnel.
              </p>
              <p className="content-text-welcome">
                Les{" "}
                <span className="text-color fw-700">
                  compétences entrepreneuriales
                </span>{" "}
                t’aident à penser et à développer un modèle d’affaires et à
                gérer les finances d’une entreprise.
              </p>
              <p className="content-text-welcome">
                La plateforme de formation te propose{" "}
                <span className="text-color fw-700">
                  cinq modules indépendants
                </span>{" "}
                en auto-apprentissage, avec à la clé de chaque module un
                certificat de participation.
              </p>
            </div>
          </div>
          <div className="home-illustration-container row">
            <div className="col-md-4 home-illustration-container-col my-4">
              <img
                className="w-full home-illustration"
                src="img/module1.png"
                alt="Module"
              />
            </div>
            <div className="col-md-4 home-illustration-container-col my-4">
              <img
                className="w-full home-illustration"
                src="img/module2.png"
                alt="Module"
              />
            </div>
            <div className="col-md-4 home-illustration-container-col my-4">
              <img
                className="w-full home-illustration"
                src="img/module3.png"
                alt="Module"
              />
            </div>
            <div className="col-md-4 home-illustration-container-col my-4">
              <img
                className="w-full home-illustration"
                src="img/module4.png"
                alt="Module"
              />
            </div>
            <div className="col-md-4 home-illustration-container-col my-4">
              <img
                className="w-full home-illustration"
                src="img/module5.png"
                alt="Module"
              />
            </div>
          </div>

          <div className="learning-theme-col">
            <div className="learning-theme-description">
              <p className="content-text-welcome text-center">
                Ces modules de formation s’adressent à toute personne résidant
                au Sénégal ayant au moins 15 ans. Le niveau BAC est recommandé.
              </p>
              <p className="content-text-welcome text-center">
                Saisis l’occasion d’être parmi les bénéficiaires en t’inscrivant
                ici !
              </p>
              <p className="content-text-welcome text-center">
                Pour cela, clique sur l’onglet{" "}
                <NavLink to="/inscription" className="h-theme fw-700">
                  {" "}
                  « Inscription »
                </NavLink>{" "}
                en haut à droite.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <MessageAllPage fullWidth={true} />
      </div>
    </div>
  );
}

export default WelcomeV4;
